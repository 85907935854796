import React, { useEffect, useState, } from 'react';
import CompanyItemComponent from '../../components/Company';
import { useSelector, useDispatch } from 'react-redux';
import { unitsAction, modalAction, companyAction } from '../../redux/actions';
import * as companyAPI from '../../api/company';

import ModalSupportOption from '../../container/Unit/Modal/modalSupportOption';
import ModalNotify from '../../features/ModalNotify';
import { errorToast, informationToast, } from '../../utils/Toast/index'; //warningToast

function CompanyContainer() {
    const [value, setValue] = useState("");
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const company = useSelector(state => state.company);
    const auth = useSelector(state => state.auth);
    const { role, branchID } = auth.user;

    useEffect(() => {
        document.title = "Company Management";
    },[]);

    function onLoadDataCompany() {
        dispatch(companyAction.callApiLoadDataCompany());
    }

    function onCheckEnter(e) {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            dispatch(companyAction.changeValueKeySearch(e.target.value))
            onLoadDataCompany();
        }
    }

    function onComfirmDeleteCompany(id) {
        const codeDelete = branchID + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

        const onDelete = async () =>  {
            //deleteCompany
            try {
                const result = await companyAPI.deleteCompany(id, token);
                if(result) {
                    if(result.data.status === 1) {
                        informationToast("" + result.data.msg);
                        window.location.reload(false);
                    } else return errorToast("Error deleting company")
                } else return errorToast("Error deleting company")
            } catch (error) {
                return errorToast("Error deleting company")
            }

        }

        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Confirm Delete Company",
            size: 'xs',
            content: < ModalSupportOption
                type={9}
                featureSupport1={onDelete}
                featureSupport2={closeModal}
                itemOption={{ schedule: undefined, code: codeDelete }}
            />
        }));
        openModal();

    }

    function onCreateNewCompany() {

        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Create New Company",
            size: 'xl',
            content: < ModalSupportOption
                type={4}
                featureSupport1={onCreateNew}
                featureSupport2={closeModal}
                itemOption={"new"}
            />
        }));

        openModal();

        function onCreateNew(dataChange) {
            //console.log("datachange", dataChange);
            if (dataChange.name === "") return errorToast("Please add Company Name");

            const updateToServer = async () => {
                dispatch(unitsAction.showLoading());
                // dispatch(unitsAction.delay());
                const resultData = await companyAPI.createNewCompany(dataChange, token);

                if (resultData) {
                    if (resultData.data.status === 2) return errorToast("" + resultData.data.msg);
                    else {
                        closeModal()

                        dispatch(modalAction.modalChange({
                            type: "centered",
                            title: "Edit Company",
                            size: 'xl',
                            content: < ModalSupportOption
                                type={4}
                                featureSupport1={onSaveData}
                                featureSupport2={closeModal}
                                itemOption={{ data: resultData.data.data, index: undefined }}
                            />
                        }));
                        openModal();

                        dispatch(unitsAction.hiddenLoading());
                        return informationToast("" + resultData.data.msg);
                    }
                } else return errorToast("" + resultData.data.msg);
            }

            updateToServer();
            dispatch(unitsAction.hiddenLoading());
        }
    }

    function onOpenEditCompany(index) {
        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Edit Company",
            size: 'xl',
            content: < ModalSupportOption
                type={4}
                featureSupport1={onSaveData}
                featureSupport2={closeModal}
                featureButton3={onComfirmDeleteCompany}
                itemOption={{ index, data: company.content[index] }}
            />
        }));

        openModal();
    }

    function onOpenHistory(index, page) {
        window.open('/company_history/'+company.content[index].company_id, "_blank");
        // const loadHistory = async () => {
        //     try {
        //         const dataResult = await companyAPI.viewCompanyHistory(company.content[index]._id, token);
        //         dispatch(modalAction.modalChange({
        //             type: "centered",
        //             title: "View History Guest data",
        //             size: 'xl',
        //             content: <ModalSupportOption
        //                 type={6}
        //                 max_width="1400px"
        //                 featureSupport1={onSaveData}
        //                 featureSupport2={closeModal}
        //                 itemOption={{ data: dataResult.data.data, type: "companyEditStory" }}
        //             />
        //         }));
        //     } catch (error) {
        //         errorToast("Error when view company history");
        //     }
        // }

        // loadHistory();
        // openModal();
    }

    function onSaveData(dataChange, type, index) {
        const editCompany = async () => {
            const dataResult = await companyAPI.editCompany(dataChange, dataChange._id, token);
            if (dataResult) {
                if (dataResult.data.status === 2) return errorToast("" + dataResult.data.msg);
                else {
                    // change data company on redungce\
                    if (index) dispatch(companyAction.changeChildrenCompany({ data: dataChange, index }))
                    return informationToast("" + dataResult.data.msg);
                }
            } else return errorToast("Error when edit company");
        }
        editCompany();
    }

    function closeModal() { dispatch(modalAction.modalReset()); }
    function openModal() { dispatch(modalAction.modalIsOk()); }

    return (
        <div >
            <ModalNotify closeModal={closeModal} />
            <h1 className="mt-5">COMPANY INFORMATION</h1>
            <div className="d-flex justify-content-center">
                <div className="d-flex">
                    <input value={value}
                        className="ml-5"
                        type={(company.typeSearch === "ID" || company.typeSearch === "Tax") ? "number" : "text"}
                        style={{ width: '200px', fontSize: '1.4rem' }}
                        onChange={e => { setValue(e.target.value); }}
                        onKeyUp={e => onCheckEnter(e)}
                        onBlur={e => {
                            if (company.typeSearch === "ID") {
                                dispatch(companyAction.changeValueKeySearch(e.target.value));
                                onLoadDataCompany();
                            } else if (e.target.value.length >= 3 && company.typeSearch !== "ID") {
                                dispatch(companyAction.changeValueKeySearch(e.target.value));
                                onLoadDataCompany();
                            }
                        }}
                    ></input>
                    <div className="dropdown">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            style={{ fontSize: '1.3rem' }}
                            type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            by {company.typeSearch}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <span
                                className="dropdown-item"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => dispatch(companyAction.changeTypeSearch("Name"))}>
                                By Name</span>
                            <span
                                className="dropdown-item"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => dispatch(companyAction.changeTypeSearch("ID"))}>
                                By ID</span>
                            <span
                                className="dropdown-item"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => dispatch(companyAction.changeTypeSearch("Tax"))}>
                                By Tax</span>
                        </div>
                    </div>
                </div>

                {/* <button className="btn btn-primary ml-2"
                    onClick={() => onLoadDataCompany()}>Find Company</button> */}
                {(role === 2 || role === 3) && <button className="btn btn-primary ml-2"
                    onClick={() => onCreateNewCompany()}>Create New Company</button>}
            </div>
            {company.content.length > 0 ?
                <CompanyItemComponent
                    onOpenHistory={onOpenHistory}
                    onOpenEditCompany={onOpenEditCompany}
                    type="company"
                    role = {role}
                    data={company.content} />
                : ""}
        </div >
    )
}

export default CompanyContainer;
