export function coverNameToID(value) {
    switch (value) {
        case 1: return "TVL1";
        case 2: return "";
        case 3: return "HBT1";
        case 4: return "LTT";
        case 5: return "";
        case 6: return "";
        case 7: return "DN";
        case 8: return "KM2";
        case 9: return "TVL2";
        case 10: return "";
        case 11: return "PP";
        case 12: return "LL";
        case 13: return "HP";
        case 14: return "";
        case 15: return "KM3";
        case 16: return "DT";
        case 91: return "TEST";
        case 96: return "TEST4";
        case 97: return "TEST3";
        case 98: return "TEST2";
        case 99: return "TEST";
        default: return "";
    }
}

export function coverBranchIDToFullName(value) {
    switch (value) {
        case 1: return "Thai Van Lung 1";
        case 2: return "";
        case 3: return "Hai Ba Trung 1";
        case 4: return "Le Thanh Ton";
        case 5: return "";
        case 6: return "";
        case 7: return "Da Nang";
        case 8: return "Kim Ma 2";
        case 9: return "Thai Van Lung 2";
        case 10: return "";
        case 11: return "Phnom Penh";
        case 12: return "Linh Lang";
        case 13: return "Hai Phong";
        case 14: return "";
        case 15: return "Kim Ma 3";
        case 16: return "Dao Tan";
        case 96: return "TEST4";
        case 97: return "TEST3";
        case 98: return "TEST2";
        case 99: return "TEST";
        default: return "";
    }
}

export function coverBranchIDToArea(value) {
    switch (value) {
        case 1: return "Ho Chi Minh";
        case 2: return "";
        case 3: return "Ha Noi";
        case 4: return "Ho Chi Minh";
        case 5: return "";
        case 6: return "";
        case 7: return "Da Nang";
        case 8: return "Ha Noi";
        case 9: return "Ho Chi Minh";
        case 10: return "";
        case 11: return "Phnom Penh";
        case 12: return "Ha Noi";
        case 13: return "Hai Phong";
        case 14: return "";
        case 15: return "Ha Noi";
        case 96: return "TEST4";
        case 97: return "TEST3";
        case 98: return "TEST2";
        case 99: return "TEST";
        default: return "";
    }
}

export function coverNumberToFullMonth(value) {
    switch (value) {
        case 1: return "Jan";
        case 2: return "Feb";
        case 3: return "Mar";
        case 4: return "Apr";
        case 5: return "May";
        case 6: return "Jun";
        case 7: return "Jul";
        case 8: return "Aug";
        case 9: return "Sep";
        case 10: return "Oct";
        case 11: return "Nov";
        case 12: return "Dev";
        default: return "";
    }
}

export function checkBranchIDHaveOnArea(branchID, area, type) {
    const LIST_AREA_ID = {
        'Ha Noi': [3, 8, 12, 15, 99],
        'Ho Chi Minh' : [1, 2, 4, 9],
        'Hai Phong / Da Nang' : [7, 13],
    }
    if(type === "check") return LIST_AREA_ID[area].includes(Number(branchID));
    if(type === "find") {
        const listKey = Object.keys(LIST_AREA_ID);
        let areaReturn = "";
        listKey.forEach(key => {
            if(LIST_AREA_ID[key].includes(Number(branchID))) areaReturn = key;
        } )
        return areaReturn;
    }

}