import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import ReactTooltip  from 'react-tooltip';

function StatusTabCheckIn(props) {
    const { target, cssWithBranch, branchID, sub, subNameTable } = props;//showType, 
    // const [showType, setShowType] = useState("1tab");
    const dataGlist = useSelector(state => state.glist);
    const [countTab, setCountTab] = useState({
        countTab1: 0,
        countTab2: 0,
        countTab3: 0,
    });
    const newDesign = true;

    const [countGuestCheckIn, setCountGuestCheckIn] = useState(0);
    const [cssCheck, setCssCheck] = useState({});
     //statusTabCheckIn1Tab
    useEffect(() => {
        const nonEmptyRoomObjects = dataGlist.listGuestCheckIn.filter(item => item.room !== "");
        setCountGuestCheckIn(nonEmptyRoomObjects.length);

        // if(Number(branchID) === 9 && dataGlist.listGuestCheckIn.length > 0) {
        //     const newCountTab = { ... countTab };

        //     dataGlist.listGuestCheckIn.forEach(checkin => {
        //         const room = checkin.room;
        //         if (DATA_CHECK_LIST_CHECK_IN.LTT.tab1.includes(room)) {
        //             newCountTab.countTab1++;
        //         } else if (DATA_CHECK_LIST_CHECK_IN.LTT.tab2.includes(room)) {
        //             newCountTab.countTab2++;
        //         }
        //     });

        //     setCountTab(newCountTab);
        // }

        setCssCheck({
            statusTabCheckIn: branchID ? cssWithBranch.statusTabCheckIn : {},
            statusTabCheckIn1Tab: branchID ? cssWithBranch.statusTabCheckIn1Tab : {},
            statusTabCheckIn2Tab: branchID ? cssWithBranch.statusTabCheckIn2Tab: {},
            statusTabCheckIn3Tab: branchID ? cssWithBranch.statusTabCheckIn3Tab: {},
        })
    },[dataGlist.listGuestCheckIn]);

    function addMoreTab() {
        const newData = [...dataGlist[target]];
        newData.push({
            room: "",
            content: ""
        },)
        updateData(newData);
    }

    function removeTab(widthTable, index) {
        // console.log("vo here", index);
        // console.log("dataGlist + widthTable", dataGlist[""+widthTable])
        const newData = [...dataGlist[""+widthTable]];
        newData.splice(index, 1);
        updateData(newData);
    }

    function onChangeData(value, targetValue, index) {
        const newData = [...dataGlist[target]];

        newData[index][targetValue] = value;
        updateData(newData);
    }

    function renderTableCheckIn(widthTable, listRoomNumberCheck, fillMoreCount) {
        //listGuestCheckIn
          let result = [];
          let countGuestCheckIn = 1;
          const type = Number(dataGlist.showType.slice(0,1));
          // Chia mảng dữ liệu thành hai phần
          const lengthList = dataGlist[widthTable].length;
          const halfLength = Math.ceil(dataGlist[widthTable].length / type);
          let firstList = dataGlist[widthTable].slice(0, halfLength);
          let secondList = dataGlist[widthTable].slice((firstList.length), firstList.length + halfLength);
          let thirdList = []

          if (type === 3) {
            thirdList = dataGlist[widthTable].slice((firstList.length + secondList.length), lengthList);
          }
    
          function renderItem(dataRender, addIndex) {
            let result2 = [];
            dataRender.forEach((item, index) => {
                if(index === 3 ) console.log("item", item)
                const dataPush = <tr>
                <td style={{ border: '1px solid black',  }}>
                    <div>{countGuestCheckIn}</div>
                </td>
                <td style={{ border: '1px solid black', width: '20px' }}>
                    <input
                        value={item.room}
                        className="no-border"
                        maxLength="4"
                        style={{ width: '100%', alignItems: 'center', textAlign: 'center', }}
                        onChange={e => onChangeData(e.target.value, "room", (index + addIndex))}
                        // onBlur={()=>updateData(listRender.data)}
                        ></input>
                </td>
                <td style={{ border: '1px solid black', position: 'relative', }}>
                    <div className="d-flex">
                        <input
                            value={item.content}
                            className="no-border"
                            style={{ 
                            width: '100%', 
                            alignItems: ( branchID === 7 || branchID === 13 ) ? "left" : 'center', 
                            textAlign: ( branchID === 7 || branchID === 13 ) ? "left" : 'center',
                            paddingLeft: ( branchID === 7 || branchID === 13 ) ? "8px" : "unset",
                            fontSize: branchID === 3 ? '1.6rem' : '1.8rem',
                        }}
                            onChange={e => onChangeData(e.target.value, "content", (index + addIndex))}
                            // onBlur={()=>updateData(listRender.data)}
                            ></input>
                    </div>
                    {/* <FontAwesomeIcon
                        icon={faMinus}
                        style={{
                            position: "absolute",
                            cursor: 'pointer',
                            color: "red",
                            // backgroundColor: 'aqua',
                            borderRadius: '40px',
                            border: '1px solid black',
                            right: 6,
                            top: 12,
                        }}
                        onClick={()=> removeTab(widthTable, index + addIndex)}
                        className="set-color no-print"
                    /> */}
                </td>
                {newDesign && <td style={{ border: '1px solid black',  }}>
                    <input style={{
                            cursor: 'pointer',
                            color: "red",
                            border: '1px solid black',
                            right: 6,
                            top: 12,
                            transform: 'scale(1.5)'
                        }}
                        onClick={() => onChangeData(!item.smoking, "smoking", (index + addIndex))}
                        checked={item.smoking}
                        type="checkbox"></input>
                 </td> }
                 {newDesign &&<td style={{ border: '1px solid black',  }}>
                    <input style={{
                            cursor: 'pointer',
                            color: "red",
                            // backgroundColor: 'aqua',
                            borderRadius: '40px',
                            border: '1px solid black',
                            right: 28,
                            top: 12,
                            transform: 'scale(1.5)'
                        }}
                        onClick={() => onChangeData(!item.water, "water", (index + addIndex))}
                        checked={item.water}
                        type="checkbox"></input>
                    </td>}
                        {/* <i className="fa-solid fa-trash" style={{color:"red", cursor: "pointer", position: "absolute",}}
                            onClick={()=> removeTab(widthTable, index + addIndex)} ></i> */}
            </tr>
                if(!listRoomNumberCheck ) {
                    result2.push(dataPush)
                } else {
                    if(listRoomNumberCheck.includes(item.room)) {
                        result2.push(dataPush)
                    }
                }
                
                countGuestCheckIn  = countGuestCheckIn + 1
              })
            return result2;
          }

          function renderExtendItem() {
            let result3 = [];
            if(fillMoreCount > 0) {
                for(let i = 0 ; i <= fillMoreCount; i++) {
                    result3.push(
                        <tr>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>

                {/* <td style={{ border: '1px solid black',  }}>{"extent"}</td>
                <td style={{ border: '1px solid black', width: '20px' }}>
                    <input
                        value={item.room}
                        className="no-border"
                        maxLength="4"
                        style={{ width: '100%', alignItems: 'center', textAlign: 'center' }}
                        onChange={e => onChangeData(e.target.value, "room", (index + addIndex))}
                        // onBlur={()=>updateData(listRender.data)}
                        ></input>
                </td>
                <td style={{ border: '1px solid black', position: 'relative', }}>
                    <input
                        value={item.content}
                        className="no-border"
                        style={{ 
                          width: '100%', 
                          alignItems: 'center', 
                          textAlign: 'center',
                          fontSize: branchID === 3 ? '1.6rem' : '2.2rem',
                      }}
                        onChange={e => onChangeData(e.target.value, "content", (index + addIndex))}
                        // onBlur={()=>updateData(listRender.data)}
                        ></input>
                    <FontAwesomeIcon
                        icon={faMinus}
                        style={{
                            position: "absolute",
                            cursor: 'pointer',
                            color: "red",
                            // backgroundColor: 'aqua',
                            borderRadius: '40px',
                            border: '1px solid black',
                            right: 6,
                        }}
                        onClick={()=> removeTab(index + addIndex)}
                        className="set-color no-print"
                    />
                </td> */}
            </tr>
                    ) 
                }

                return result3;

            }
          }

        // if (showType ==="1tab") {
        result.push(<table key={countGuestCheckIn}>
                <tr>
                    <th style={{ border: '1px solid black', width: '30px',height:'15px' }}>No.</th>
                    <th style={{ border: '1px solid black', width: '50px' }}>ROOM</th>
                    <th style={{
                        border: '1px solid black',
                        width: dataGlist.showType === "1Tab" ? '1100px' :
                                dataGlist.showType === "2Tab" ? "550px" : "300px"
                    }}>
                     Yêu Cầu
                    </th>
                    {newDesign &&  <th style={{ border: '1px solid black', width: '30px',height:'15px' }}><i className="fa-solid fa-smoking" style={{fontSize:'1.8rem', color:"green"}}></i></th>}
                    {newDesign &&  <th style={{ border: '1px solid black', width: '50px',height:'15px' }}>2<i className="fa-solid fa-bottle-water ml-2" style={{fontSize:'1.8rem', color:"green"}}></i></th>}
                </tr>
                {/* {renderItem(dataGlist.listGuestCheckIn)} */}
                {renderItem(firstList, 0)}
                {/* {branchID === 9 && renderExtendItem()} */}
            </table>)
        // }

        if (dataGlist.showType !=="1Tab") {
            result.push(<table>
                    <tr>
                        <th style={{ border: '1px solid black', width: '30px',height:'15px' }}>No.</th>
                        <th style={{ border: '1px solid black', width: '50px' }}>ROOM</th>
                        <th style={{
                            border: '1px solid black',
                            width: dataGlist.showType === "1Tab" ? '1100px' :
                                dataGlist.showType === "2Tab" ? "550px" : "300px"
                        }}>Yêu Cầu</th>
                    {newDesign &&  <th style={{ border: '1px solid black', width: '30px',height:'15px' }}><i className="fa-solid fa-smoking" style={{fontSize:'1.8rem', color:"green"}}></i></th>}
                    {newDesign &&  <th style={{ border: '1px solid black', width: '50px',height:'15px' }}>2<i className="fa-solid fa-bottle-water ml-2" style={{fontSize:'1.8rem', color:"green"}}></i></th>}
                    </tr>
                   {renderItem(secondList, firstList.length)}
                </table>)
        }

        if (dataGlist.showType ==="3Tab") {
            result.push(<table>
                    <tr>
                        <th style={{ border: '1px solid black', width: '30px',height:'15px' }}>No.</th>
                        <th style={{ border: '1px solid black', width: '50px' }}>ROOM</th>
                        <th style={{
                            border: '1px solid black',
                            width: dataGlist.showType === "1Tab" ? '1100px' :
                                dataGlist.showType === "2Tab" ? "550px" : "300px"
                        }}>Yêu Cầu</th>
                    {newDesign &&  <th style={{ border: '1px solid black', width: '30px',height:'15px' }}><i className="fa-solid fa-smoking" style={{fontSize:'1.8rem', color:"green"}}></i></th>}
                    {newDesign &&  <th style={{ border: '1px solid black', width: '50px',height:'15px' }}>2<i className="fa-solid fa-bottle-water ml-2" style={{fontSize:'1.8rem', color:"green"}}></i></th>}
                    </tr>
                   {renderItem(thirdList, firstList.length + secondList.length)}
                </table>)
        }

        return result;
    }

    function renderTabletCheckInLTT() {
        {renderTableCheckIn()}
        {renderTableCheckIn()}
    }

    function updateData(data) {
        props.updateStatusTabArray(target,data);
    }  

    function changeShowType(value) {
        props.updateStatusTabArray("showType", value);
    }

    return (<>
        {/* <div className="d-flex" style={{ border: '1px solid black', padding: '4px' }}>
            <div style={{ padding: '4px' }}>Lễ tân thực hiện: </div>
            <div style={{ padding: '4px' }}>Thảo</div>
        </div> */}
        <div className="checkin" style={{...cssCheck["statusTabCheckIn"+dataGlist.showType]}}>
            <div style={{
                position: 'relative',
                border: '1px solid black',
                // padding: '4px',
                fontWeight: '600',
                backgroundColor: 'rgb(194 246 139 / 43%)',
                // color: 'white',
                fontSize: '1.2rem'
            }}>
                <div
                    onClick={() => addMoreTab()}
                    className="no-print"
                    style={{
                        position: "absolute",
                        border: '1px solid black',
                        backgroundColor: 'white',
                        // padding: '2px',
                        borderRadius: '20px',
                        cursor: 'pointer',
                        color: "blue",
                        fontSize: '1.4rem'
                    }}>
                    <FontAwesomeIcon
                        icon={faPlus}
                        style={{ cursor: 'pointer', color: "blue" }}
                        className="set-color"
                    /><span>Add more</span>
                </div>
                PHÒNG CHECK IN
                <div className="radio" style={{
                    ...cssCheck.statusTabCheckIn1Tab,
                    position: "absolute",
                    fontSize: '1.3rem', 
                    top: "0",
                    right: "0"
                }}>
                    {(branchID !== 9 && branchID !== 1) && <label className="language no-print">
                        <strong>Show type:</strong>
                        <select id="currency"
                            onChange={e => changeShowType(e.target.value)}>
                            <option value="1Tab" selected ={dataGlist.showType === "1Tab"}>1 tab</option>
                            <option value="2Tab" selected ={dataGlist.showType === "2Tab"}>2 tab</option>
                            <option value="3Tab" selected ={dataGlist.showType === "3Tab"}>3 tab</option>
                        </select>
                    </label>}
                </div>
            </div>
            <div className="d-flex">
                {(branchID === 9 || branchID === 1) ? 
                <div>
                    <div style={{background:"yellow", fontSize:'1.5rem', fontWeight:'600', border:'1px solid'}}>{subNameTable}</div>
                    {renderTableCheckIn(target, undefined, (countTab.countTab1 - countTab.countTab2)) }
                </div>
                 : renderTableCheckIn("listGuestCheckIn")}
            </div>

            {(branchID !== 1 && branchID !== 9) && <div style={{ textAlign: 'left', fontWeight: '600', fontSize: '1.3rem', border: '1px solid black' }}>
                <label className="ml-3 mr-3">Tổng:</label>
                <input 
                    style={{ width: '50px', border: '0px solid black', marginLeft: '8px', fontSize: '1.5rem',}} 
                    value = {countGuestCheckIn}
                    maxLength="2"></input>
            </div>}
        </div>
    </>)
}

export default StatusTabCheckIn;
