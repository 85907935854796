export const RATE_VAT = 0.08;
export const RANK_COLOR = {
    Bronze: "rgba(199, 142, 115, 0.25)",
    Silver: "rgba(173, 173, 173, 0.25)",
    Gold: "rgba(192, 148, 61, 0.25)",
    Platinum: "rgba(155, 226, 218, 0.25)",
    Diamond: "rgba(235, 167, 192, 0.25)"
};

export const RANK_COLOR2 = {
    Bronze : "#c78e73",
    Silver : "#adadad",
    Gold : "#c0943d",
    Platinum : "#9be2da",
    Diamond : "#eba7c0",
};

export const RANK_COLOR3 = {
    Bronze : 'linear-gradient( #95513e, #e7a688, #a45942)',
    Silver : 'linear-gradient( #f3f3f3, #bcbcba, #f5f5f3, #bcbcba)',
    Gold : 'linear-gradient( #ffce41, #f2af23, #e18a07)',
    Platinum : 'linear-gradient( #049c9e, #a6f3f5)',
    Diamond : 'linear-gradient( #992fd9, #f49190)',
};
