import React, { useLayoutEffect, useCallback, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// import HomePage from './container/Reservation/ReservationContainer';
import LoginContainer from './container/Unit/LoginContainer';
import ReservationContainer from './container/Reservation/ReservationContainer';
import MakeRoomContainer from './container/MakeRoom';
import CompanyContainer from './container/Company';
import GuestInformationContainer from './container/GuestInformation';
import CancelAreaContainer from './container/CancelArea';
import WaitingAreaContainer from './container/WaitingArea';
import MakeBookingBill from './container/MakeBookingBill';
import AddServiceOnBooking from './container/MakeHotelInvoice/AddServiceOnBooking';
import MakeServiceInvoice from './container/MakeHotelInvoice/MakeServiceInvoice';
import MakeHotelInvoice from './container/MakeHotelInvoice/MakeHotelInvoice';
import InvoiceManagerContainer from './container/Invoice';
import ViewPdf from './container/Unit/ViewPdf';
import GlistContainer from './container/Glist/GlistContainer';
import SupportReportContainer from './container/SupportReport';
import GuestNumberContainer from './container/Unit/SupportTools/GuestNumber/GuestNumberContainer';
import GuestCheckInContainer from './container/Unit/SupportTools/GuestCheckIn/GuestCheckInContainer';
import MakePMMRQContainer from './container/MakePMRQ';
import FightManageContainer from './container/FlightManage';
import GuestCheckInManageContainer from './container/GuestCheckInManage';
import GuestCheckOutAccContainer from './container/Unit/SupportTools/GuestCheckOut/GuestCheckOutAccContainer';
import MailContainer from './container/MailContainer';
import MakeServiceBill from './container/MakeServiceBill';
import CompanyEditHistory from './components/Company/companyHistory';
import SetupPromotion from './container/Unit/SupportTools/SetupPromotion/SetupPromotion';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function NewRouter(props) {
    //const auth = useSelector(state => state.auth);
    // const { isLoggedIn, } = auth;
    const token = useSelector(state => state.token);
    const firstLogin = localStorage.getItem('firstLogin');
    const [isAccess, setIsAccess] = useState(true);
    const [countLoad, setCoundLoad] = useState(0);

    const location = useLocation();

    useEffect(() => {
        if(location.pathname === "/" || location.pathname === "/login") {
            if(firstLogin)  setIsAccess(true);
        } else {
            if(firstLogin) {
                setIsAccess(true);
                if(token) {
                    setIsAccess(true);
                } else {
                    setCoundLoad(countLoad+1)
                    if (countLoad === 1000) {
                        window.location.href = "/";
                    }
                }
            } else  {
             window.location.href = "/"
            }
        }
    },[countLoad])

    // useLayoutEffect(() => {
    //     setTimeout(() => {
    //     }, 5000);
    // }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const checkLogin = useCallback(() => {
    //     if (!firstLogin) return LoginContainer;
    //     else return !isLoggedIn ? LoginContainer : "";
    //     // HomePage
    // })

    return (
        <section style={{ margin: 'auto',width:'maxContent'}}>
            <Switch>
                {/* <Route path="/user/profile" component={!isLoggedIn ? NotFound : ProfileContainer} exact /> */}
                <Route path="/" component={LoginContainer} exact />
                <Route path="/login" component={LoginContainer} exact />
                {/* <Route path="/home" component={isAccess && ReservationContainer} exact /> */}
                <Route path="/cancel" component={isAccess && CancelAreaContainer} exact />
                <Route path="/glist" component={isAccess && GlistContainer} exact />
                <Route path="/glist/:branchID/:day" component={isAccess && GlistContainer} exact />
                <Route path="/invoice" component={isAccess && InvoiceManagerContainer} exact />
                <Route path="/flight" component={isAccess && FightManageContainer} exact />
                <Route path="/checkin" component={isAccess && GuestCheckInManageContainer} exact />
                <Route path="/invoice/:month" component={isAccess && InvoiceManagerContainer} exact />
                <Route path="/viewPDF/:branch/:link" component={isAccess && ViewPdf} exact />
                <Route path="/waiting" component={isAccess && WaitingAreaContainer} exact />
                <Route path="/make_booking_bill/:branchID/:idRoom" component={ isAccess && MakeBookingBill} exact />
                <Route path="/make_booking_bill/:branchID/:type/:idRoom" component={ isAccess && MakeBookingBill} exact />
                <Route path="/make_service_bill/:branchID/:idRoom" component={ isAccess && MakeServiceBill} exact />
                <Route path="/add_service/:branchID/:idRoom" component={ isAccess && AddServiceOnBooking} exact />
                <Route path="/service_invoice/:branchID/:idRoom" component={ isAccess && MakeServiceInvoice} exact />
                <Route path="/make_pmrq/:branchID/:idRoom" component={ isAccess && MakePMMRQContainer} exact />
                <Route path="/make_pmrq/:branchID/:type/:idRoom" component={ isAccess && MakePMMRQContainer} exact />
                <Route path="/make_invoice/:branchID/:idRoom" component={ isAccess && MakeHotelInvoice} exact />
                <Route path="/make_invoice/:branchID/:type/:idRoom" component={MakeHotelInvoice} exact />
                <Route path="/reservation" component={isAccess && ReservationContainer} exact />
                <Route path="/reservation/:branchID/:month" component={isAccess && ReservationContainer} exact />
                <Route path="/room" component={isAccess && MakeRoomContainer} exact />
                <Route path="/room/:branchID/:roomSelect/:checkin" component={isAccess && MakeRoomContainer} exact />
                <Route path="/room/:branchID/:idRoom" component={isAccess && MakeRoomContainer} exact />
                <Route path="/room_history/:branchID/:id_history" component={isAccess && MakeRoomContainer} exact />
                <Route path="/check_room/:branchID/:idRoom/:typeRoom" component={MakeRoomContainer} exact />
                <Route path="/company" component={isAccess && CompanyContainer} exact />
                <Route path="/company_history/:id" component={isAccess && CompanyEditHistory} exact />

                <Route path="/guest" component={isAccess && GuestInformationContainer} exact />
                <Route path="/mail" component={isAccess && MailContainer} exact />

                <Route path="/guest/:id" component={isAccess && GuestInformationContainer} exact />
                <Route path="/report" component={isAccess && SupportReportContainer} exact />
                {/* Support Tools */}
                <Route path="/guest_number" component={isAccess && GuestNumberContainer} exact />
                <Route path="/setup_promotion" component={isAccess && SetupPromotion} exact />
                <Route path="/guest_checkin" component={isAccess && GuestCheckInContainer} exact />
                <Route path="/guest_checkout" component={isAccess && GuestCheckOutAccContainer} exact />
            </Switch>
        </section>
    );
}

export default NewRouter;