import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const style = {
    container: {
        textAlign: "left",
        marginLeft: 20,
        backgroundColor: '#d8dbdd'
    },
    icon: {
        color: 'blue',
        fontSize: '1.5rem',
        textAlign: 'center',
        alignItems: 'center',
        marginTop: 5,
        marginLeft: 5,
    },
    children: {
        border: '0.5px solid rgba(0,255,255,0.8)',
        padding: '4px'
    },
    label: {
        fontSize: '1.4rem',
        width: '120px',
        margin: 0,
        padding: 0,
        fontWeight: '500',
        textAlign: 'left'
    },
    textarea: {
        width: '520px',
        fontSize: '1.4rem',
    },
    text: {
        fontSize: '1.4rem'
    }
};

function CompanyEditDetails(props) {
    const auth = useSelector(state => state.auth);
    const { role } = auth.user;
    const isDisabled = (role !== 2 && role !== 3 && role !== 6);

    const [dataState, setDataState] = useState({
        _id: "",
        name: "",
        address: "",
        branch: "",
        company_id: 0,
        contract: false,
        travel: false,
        represent_name: "",
        special: "",
        special_rate: 0,
        special_notify: false,
        tax: "",
        tel: "",
        note: "",
    });

    useEffect(() => {
        if (props.data) {
            if (typeof props.data === 'object') setDataState({...dataState, ...props.data})
        }
    }, [props.data]);

    function onChangeDate(value, target, option) {
        const newData = { ...dataState };
        newData[target] = value;
        setDataState(newData);
    }

    return (
        <div >
            <div className="d-flex ">
                <div style={{ width: '100%' }} >
                    <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Name(*)</label>
                        <input
                            disabled = {isDisabled}
                            type="text"
                            style={{ width: '80%' }}
                            value={dataState.name}
                            onChange={e => onChangeDate(e.target.value, "name", "")}
                        />
                    </div>

                    <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Contract ?</label>
                        <div class="input-group-prepend " style={{ width: '80%' }}>
                            <div class="input-group-text">
                                <input
                                    disabled = {isDisabled}
                                    type="radio"
                                    id="yes"
                                    checked={dataState.contract}
                                    onChange={() => onChangeDate(true, "contract", "")}
                                />
                                <label style={style.text} for="yes">Yes</label>
                            </div>

                            <div class="input-group-text">
                                <input
                                    disabled = {isDisabled}
                                    type="radio"
                                    id="no"
                                    checked={!dataState.contract}
                                    onChange={() => onChangeDate(false, "contract", "")}
                                />
                                <label style={style.text} for="no">No</label>
                            </div>
                        </div>
                    </div>

                    <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Travel ?</label>
                        <div class="input-group-prepend " style={{ width: '80%' }}>
                            <div class="input-group-text">
                                <input
                                    disabled = {isDisabled}
                                    type="radio"
                                    id="yes"
                                    checked={dataState.travel}
                                    onChange={() => onChangeDate(true, "travel", "")}
                                />
                                <label style={style.text} for="yes">Yes</label>
                            </div>

                            <div class="input-group-text">
                                <input
                                    disabled = {isDisabled}
                                    type="radio"
                                    id="no"
                                    checked={!dataState.travel}
                                    onChange={() => onChangeDate(false, "travel", "")}
                                />
                                <label style={style.text} for="no">No</label>
                            </div>
                        </div>
                    </div>

                    <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Address</label>
                        <textarea 
                            disabled = {isDisabled}
                            style={{ width: '80%', height: '60px' }}
                            onChange={e => onChangeDate(e.target.value, "address", "")}
                            value={dataState.address}></textarea>
                    </div>

                    <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Represent name</label>
                        <input
                            disabled = {isDisabled}
                            type="text"
                            style={{ width: '80%' }}
                            value={dataState.represent_name}
                            onChange={e => onChangeDate(e.target.value, "represent_name", "")}
                        />
                    </div>

                    <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Tax</label>
                        <input
                            disabled = {isDisabled}
                            type="text" style={{ width: '80%' }}
                            value={dataState.tax}
                            onChange={e => onChangeDate(e.target.value, "tax", "")}
                        />
                    </div>

                    <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Phone</label>
                        <input
                            disabled = {isDisabled}
                            type="text" style={{ width: '80%' }}
                            value={dataState.tel}
                            onChange={e => onChangeDate(e.target.value, "tel", "")}
                        />
                    </div>

                    <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Special</label>
                        <textarea 
                            disabled = {isDisabled}
                            style={{ width: '80%', height: '60px' }}
                            onChange={e => onChangeDate(e.target.value, "special", "")}
                            value={dataState.special}></textarea>
                    </div>

                    {/* <div className="d-flex"> */}
                    {/* <div style={style.children} className="d-flex justify-content-around op-hidden">
                        <label style={{ ...style.label, }} className='op-hidden'>
                            Notification special?</label>

                        <div class="input-group-prepend ml-2 op-hidden" style={{ width: '80%' }}>
                            <div class="input-group-text">
                                <input
                                    disabled = {isDisabled}
                                    type="radio"
                                    id="special_notify_yes"
                                    checked={dataState.special_notify}
                                    onChange={() => onChangeDate(true, "special_notify", "")}
                                />
                                <label style={style.text} for="special_notify_yes">Yes</label>
                            </div>

                            <div class="input-group-text">
                                <input
                                    disabled = {isDisabled}
                                    type="radio"
                                    id="special_notify_no"
                                    checked={!dataState.special_notify}
                                    onChange={() => onChangeDate(false, "special_notify", "")}
                                />
                                <label style={style.text} for="special_notify_no">No</label>
                            </div>
                        </div>
                    </div> */}

                    {
                    // Waiting Update
                        props.data && <div style={style.children} className="d-flex justify-content-around">
                        <label style={{...style.label, color:"red"}}>Reason for the change (*)</label>
                        <textarea 
                            disabled = {isDisabled}
                            style={{ width: '80%', height: '60px',border: '1px solid red' }}
                            onChange={e => onChangeDate(e.target.value, "note", "")}
                            value={dataState.note}></textarea>
                    </div>
                    }
                    

                    {/* <div style={style.children} className="d-flex justify-content-around">
                        <label style={style.label}>Special rate</label>
                        <input
                         type="number" style={{ width: '80%' }}
                            value={dataState.special_rate}
                            onChange={e => onChangeDate(e.target.value, "special_rate", "")}
                        />
                    </div> */}
                </div>
            </div>
            {
                
                !isDisabled && <div class="mt-1">
                {/* Waiting update */}
                {/* <button className="btn btn-primary"
                    disabled={props.data === undefined ? false : ((dataState.note !== "") ? false : true)}
                    onClick={() => props.onSave(dataState, !props.data ?
                        "create_new" : "change", !props.data ? "" : props.data.index)}>
                    {props.data === undefined ? "Create New" : "Save Change"}
                </button> */}

                <button className="btn btn-primary"
                   // disabled={props.data === undefined ? false : ((dataState.note !== "") ? false : true)}
                    onClick={() => props.onSave(dataState, !props.data ?
                        "create_new" : "change", !props.data ? "" : props.data.index)}>
                    {props.data === undefined ? "Create New" : "Save Change"}
                </button>

                <button className={props.data === undefined ? "op-hidden" : "btn btn-danger ml-2"}
                    onClick={() => props.onDelete(dataState._id)}>
                    {props.data === undefined ? "" : "Delete"}
                </button>
                <button className="btn btn-danger ml-2"
                    onClick={() => props.onClose()}>Cancel</button>
            </div>
            }

        </div >
    )
}

export default CompanyEditDetails;
