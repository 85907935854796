import { combineReducers } from 'redux';
import companyReducer from './company/company';
import guestInformationReducer from './guestInformation/guestInformation'
import makeHotelInvoiceReducer from './hotelInvoice/makeHotelInvoice';
import invoiceManageReducer from './invoiceManage/invoiceManage';
import makeRoomReducer from './makeRoom/makeRoom';
import makeRoomOtherReducer from './makeRoom/makeRoomOther';
import reservationReducer from './reservation/reservation';
// import allBranchReducer from './units/allBranch';
import authReducer from './units/auth';
import branchReducer from './units/branchInfor';
import dataPickUpReducer from './units/dataPickUp';
import modalReducer from './units/modal';
import showLoadingReducer from './units/showLoading';
import tokenReducer from './units/token';
import glistReducer from './glist/glist';
import flightManageReducer from './flightManage/flightManage';

const rootReducer = combineReducers({
    reservation: reservationReducer,
    makeRoom: makeRoomReducer,
    makeRoomOther: makeRoomOtherReducer,
    dataPickUp: dataPickUpReducer,
    // allBranch: allBranchReducer,
    branchInfor: branchReducer,
    makeHI: makeHotelInvoiceReducer,
    company: companyReducer,
    invoiceManage: invoiceManageReducer,
    flightManage: flightManageReducer,
    guestInformation: guestInformationReducer,
    modal: modalReducer,
    showLoading: showLoadingReducer,
    glist: glistReducer,
    auth: authReducer,
    token: tokenReducer
})

export default rootReducer;