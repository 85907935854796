import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";

// API & Constants
import * as pmrqApi from '../../api/pmrq';
import { takeDataWithHotelInvoice  } from '../../api/invoice';
import { ADDRESS_CHANGE, BANK_ACCOUNT } from '../../constaints/pmrq/pmrq';

// action & support
import { unitsAction, modalAction } from '../../redux/actions';
import { changeStringVNDtoNumber } from '../../utils/Currency/SupportCurrency';
import { getNights, getTodayFullFormat, getTodayFullFormat_DDMMYYY, 
    increaseTodayWithNumber, changeDateToString, changeDataToDDMMYYYY2 } from '../../utils/Date/SupportDate';
import { coverBranchIDToArea } from '../../utils/Branch/SupportBranch';
import { coverNameToID } from "../../utils/Branch/SupportBranch";
import { errorToast, informationToast, } from '../../utils/Toast/index'; //warningToast, 
import { RATE_VAT } from "../../constaints/other/otherConstaints";

import ModalSupportOption from "../Unit/Modal/modalSupportOption";
import FooterButton from "../../utils/FooterButton";

import './pmrq.css'

function MakePMMRQContainer() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const { branchID, idRoom, type } = useParams();
    const token = useSelector(state => state.token);
    const { role } = auth.user;
    const isHNAreaBranch = (Number(branchID) === 3 || Number(branchID) === 12 || Number(branchID) === 8 || Number(branchID) === 15 || Number(branchID) === 16 || Number(branchID) === 99);
  //  var listSelectDelete = [];

    var sumVAT;
    const [data, setData] = useState(
        {
            branchInformation: {
                address: "",
                name: "Azumaya",
                phone: "",
                
            },
            listItem: [], 
            listHISpan: {},
            companyName: "",
            specialNote: "",
            dateFormat: "dd/MM/yyyy",
            VATRate: RATE_VAT,
            signName: "",
            signDate: getTodayFullFormat().slice(0,10),
            hiddenRoomNumber: false,
            hiddenDate: false,
            payBefore2Day: false,
            bankAccount: "VND",
            dateRequestPayment: "",
            timeRequestPayment: "15:00",
            dateRequestPaymentDDYYMM: "",
            listSelectDelete: [],
            isUpdate: false,
        }
    );

    useEffect(()=> {
        if (token) {
            // if (Number(branchID) === 99 ) loadNewData2(token);
            // else loadNewData(token);

            loadNewData2(token);
           // checkDataHotelInvoice(token);
        }
    }, [auth.user.branchID]);

    useEffect(()=> {
        document.title = `Az${coverNameToID(Number(branchID))}_PMRQ TO ${data.companyName}_${getTodayFullFormat_DDMMYYY().slice(0,10)}`;
    },[data.companyName]);

    window.onbeforeprint = function() {
        setData({
            ...data,
            dateRequestPaymentDDYYMM: changeDataToDDMMYYYY2(data.dateRequestPayment) ,
        })
    }

    function matchRoad2(data) {
        return Math.round(data*100)/100;
    }

    function matchRoad3(data) {
        return Math.round(data*1000)/1000;
    }

    const loadNewData2 = async (token, type) => {
        dispatch(unitsAction.showLoading());
        // check type = group => load group ID
        const result = await pmrqApi.getDataPaymentRequest(branchID, type ? type : "normal", idRoom, token);
        //listItem
        if("data" in result) {
            // make guest
            // let guestName = ""
            // result.data.data.listGuestSelect.forEach(guest => {
            //     guestName = (guestName ===""? "": ", ")  + (guest.gender ? "Ms.":"Mr." + guest.name)
            // })

            const dateNeedPay = new Date(increaseTodayWithNumber(getTodayFullFormat(),"day",9))

            setData({
                ...data,
                branchInformation: result.data.branchInformation,
                listItem: result.data.data.listItem,
                listHISpan:  result.data.data.listHISpan,
                specialNote: result.data.specialNote,
                companyName: result.data.companyName,
                bankAccount: result.data.bankAccount,
                dateRequestPayment: dateNeedPay,
                dateRequestPaymentDDYYMM: changeDataToDDMMYYYY2(dateNeedPay)
            });
        }
        document.title = `Az${coverNameToID(Number(branchID))}_PMRQ TO ${result.data.companyName}_${getTodayFullFormat_DDMMYYY().slice(0,10)}`;

        dispatch(unitsAction.hiddenLoading());
    }

    function checkSelectDelete(id) {
        const newData = {...data };
        if(newData.listSelectDelete.length > 0) {
            if(newData.listSelectDelete.includes(id)) newData.listSelectDelete.splice(newData.listSelectDelete.indexOf(id), 1); 
            else newData.listSelectDelete.push(id);
        } else newData.listSelectDelete.push(id);

        setData(newData);
    }

    function addMoreColumn() {
        const newData = {...data};
        newData.listItem.push({
            name: "",
            invoice:"",
            VAT_invoice:"",
            checkIn:"",
            checkOut:"",
            room_number:"",
            nights:"",
            price:"",
            sub:"",
            VAT:"",
            total:"",
        })

        setData(newData);
    }

    function addMoreHI() {
        let resultData

        function onAddInvoice(dataSelect) {
            const newData = {...data};

            dataSelect.forEach(dataSelectItem => {
                dataSelectItem.listGuestOnHI.forEach(item => {
                    newData.listItem.push({
                        name: item.name,
                        invoice: item.invoice,
                        VAT_invoice:"",
                        checkIn:item.checkIn,
                        checkOut:item.checkOut,
                        room_number:item.roomSelect,
                        nights:item.nights,
                        price:item.price,
                        sub: item.sub,
                        VAT: item.VAT,
                        total: item.total,
                    })
                })
            })
            setData(newData);
            closeModal();
        }

        function onHandleFindInvoice(value) {
            // console.log("typeSearch", typeSearch)
            const findInvoice = async () => {
                resultData = await takeDataWithHotelInvoice(
                    branchID, 
                    {value},
                    token);

                if(resultData.data) {
                    dispatch(modalAction.modalChange({
                        type: "centered",
                        title: "Find Invoice",
                        size: 'xl',
                        content: < ModalSupportOption
                            type={15}
                            featureSupport1={onAddInvoice}
                            featureSupport2={closeModal}
                            itemOption ={resultData.data.data}
                            featureButton1={onAddInvoice}
                            featureButton2={() => closeModal()}
                        />
                    }));
                    openModal();
                }
            }
            if(value.length >=3) {
                findInvoice()
            }
        }

        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Find Invoice",
            size: 'xl',
            content: < ModalSupportOption
                type={15}
                featureSupport1={onHandleFindInvoice}
                featureSupport2={closeModal}
                itemOption ={{}}
                featureButton1={() => {}}
                featureButton2={() => closeModal()}
   
            />
        }));
        openModal();
    }

    function updateListHISpan(newData) {
        let listHISpan = {}
        if(newData.listItem.length > 0) {
          //  const list = data.listItem.reverse();
          
          data.listItem.forEach(item => {
                const invoice = item.invoice;

                if(invoice in listHISpan) {
                    listHISpan[invoice] += 1;
                } else listHISpan[invoice] = 1;
            //    console.log("listHIspan", listHISpan)
            })
        }
        //make again listHIspan
       setData({...newData, listHISpan});
    };

    function resetPaymentRequest() {
        dispatch(modalAction.modalChange({
            type: "centered",
            title: "System notification",
            size: 'xs',
            content: < ModalSupportOption
                type={99}
                featureSupport1={""}
                featureSupport2={closeModal}
                component={<><div>Do you want to reset the data to the original HI data</div>
                        <div>*Clear data update / you can reload if need keep data</div>
                        <div>---</div>
                        <div>Bạn có muốn reset lại dữ liệu như dữ liệu HI ban đầu</div>
                        <div>*Muốn lấy lại dữ liệu cũ sau reset,</div>
                        <div>hãy load lại trang và không ấn Save</div>
                        <br/>
                    </>}
                button1 ="Reset"
                button2 ="cancel"
                typeButton1= "btn-primary"
                typeButton2= "btn-danger"
                featureButton1={() => {
                    closeModal()
                    loadNewData2(token, "reset")
                }}
                featureButton2={() => closeModal()}
   
            />
        }));
        openModal();
    }

    function updatePaymentRequest(print) {
        const newData = {...data}
        let listHISpan = {};

        const update = async () => {
            const checkUpdate = await pmrqApi.updatePaymentRequest(branchID, idRoom, {
                ...data,
                booking_id : idRoom
            }, token);

            if(checkUpdate) {
                informationToast("" + checkUpdate.data.msg);
                if(print ==="print") {
                    window.print()
                    setData({...newData, listHISpan, isUpdate: false})
                } 
            } else return errorToast("Error updating payment request")
        }

        if(newData.companyName === "") {
            dispatch(modalAction.modalChange({
                type: "centered",
                title: "System notification",
                size: 'xs',
                content: < ModalSupportOption
                    type={88}
                    featureSupport1={""}
                    featureSupport2={closeModal}
                    itemOption={{
                        title: <>
                            <div>Company name is empty, please add it</div>
                            <div>Tên công ty bị bỏ trống, xin hãy bổ sung</div>
                        </>,
                        color: "red"
                    }}
                />
            }));
            openModal();
        } else {
            if(print !=="print") {
                update();
            } else {
                // const sortListItem = newData.listItem.sort((a, b) => {
                //     if ((a.invoice) > (b.invoice)) return 1;
                //     else if ((a.invoice) < (b.invoice)) return -1;
                //     else return 0;
                // });

                const sortListItem = newData.listItem.sort((a, b) => {
                    if ((a.VAT_invoice) > (b.VAT_invoice)) return 1;
                    else if ((a.VAT_invoice) < (b.VAT_invoice)) return -1;
                    else return 0;
                });
    
                if(newData.listItem.length > 0) {
                  //  const list = data.listItem.reverse();
                  newData.listItem.forEach(item => {
                        const invoice = item.invoice+item.room_number;
        
                        if(invoice in listHISpan) {
                            listHISpan[invoice] += 1;
                        } else listHISpan[invoice] = 1;
                    })
                }
                //make again listHIspan
               setData({...newData, listItem: sortListItem, listHISpan, isUpdate: true})
               update();
            }
        }
    }

    function onChangeData(value, target, option) {
        const newData = {...data};

        if(option === "" || option === undefined) {
            // console.log("value", value, target, option)
            if(target === "VATRate") {
                const newListItem = newData.listItem.map(item => {
                    item.total = item.nights ? Math.round((1 + changeStringVNDtoNumber(value)) * item.sub) : item.total;
                    return item;
                })     

                newData[target] = changeStringVNDtoNumber(value);
                newData["listItem"] = newListItem;
            } else if(target === "bankAccount") {
                newData[target]= value;
                if(newData.listItem.length > 0) {
                    let roomRateOneNight;
                    newData.listItem.forEach(item => {
                        if(value === "USD") {
                            roomRateOneNight = matchRoad2(changeStringVNDtoNumber(item.price)/25000);
                            if(item.room_number !== "") {
                                item.price = roomRateOneNight;
                                item.sub = matchRoad2(changeStringVNDtoNumber(item.sub)/25000);
                                item.VAT = matchRoad2(changeStringVNDtoNumber(item.VAT)/25000);
                            }
                            item.total = matchRoad2(changeStringVNDtoNumber(item.total)/25000);

                        } else {
                            roomRateOneNight = matchRoad2(changeStringVNDtoNumber(item.price)*25000);
                            const VAT = changeStringVNDtoNumber(item.VAT)
                            if(item.room_number !== "") {
                                item.price = roomRateOneNight;
                                item.sub = matchRoad2(changeStringVNDtoNumber(item.sub)*25000*1000)/1000;
                                item.VAT = matchRoad2(VAT*25000*1000)/1000;
                            }
                            item.total = (changeStringVNDtoNumber(item.price)* item.nights) * ( 1 + (item.VATRate ? Number(item.VATRate): RATE_VAT))
                        }
                    })
                }
            } else if (target === "signDate") {
                newData[target] = changeDateToString(value);
            } else if(target === "payBefore2Day") {
                newData[target]= value;
                if(newData.listItem.length > 0 && value === true) {
                    if(newData.listItem[0].checkIn) {
                        // let dateAdd2Day = new Date(newData.listItem[0].checkIn);
                        // dateAdd2Day.setDate(dateAdd2Day.getDate() - 2);

                        // console.log("dateAdd2Day", dateAdd2Day)
                        // // Format the date to a readable format
                        // let dd = String(dateAdd2Day.getDate()).padStart(2, '0');
                        // let mm = String(dateAdd2Day.getMonth() + 1).padStart(2, '0'); //January is 0!
                        // let yyyy = dateAdd2Day.getFullYear();

                        //let newDate = mm + '/' + dd + '/' + yyyy;

                        // const checkDay = getNights(newData.listItem[0].checkIn, getTodayFullFormat());
                        // if(checkDay > 2) newData.dateRequestPayment = dateAdd2Day// new Date(increaseTodayWithNumber(newData.listItem[0].checkIn,"day",-2));
                        // else newData.dateRequestPayment = new Date();

                        newData.dateRequestPayment = new Date(increaseTodayWithNumber(newData.listItem[0].checkIn,"day",-2));
                    }
                } else {
                    newData.dateRequestPayment =new Date(increaseTodayWithNumber(getTodayFullFormat(),"day",9))
                }
            }

            else if(target === "dateRequestPayment") {
              newData.dateRequestPaymentDDYYMM = changeDataToDDMMYYYY2(value);
              newData[target]= value
            }
            else newData[target]= value;
            
        } else {
            newData.listItem[option][target]= value;
            if(target ==="checkIn" || target === "checkOut") {
                const nights = getNights(newData.listItem[option].checkIn, newData.listItem[option].checkOut)
                if(nights > 0) {
                    newData.listItem[option].nights = nights;
                    newData.listItem[option].sub = matchRoad2(nights * changeStringVNDtoNumber(newData.listItem[option].price));
                    newData.listItem[option].total = matchRoad2(nights * ((1 + newData.VATRate) * changeStringVNDtoNumber(newData.listItem[option].price)));
                } else newData.listItem[option][target] = ""
                newData.listItem[option][target]= changeDateToString(value);
            } 
            if (target === "nights") {
                newData.listItem[option].sub = matchRoad2(changeStringVNDtoNumber(newData.listItem[option].price) * value);
                newData.listItem[option].total = matchRoad2(changeStringVNDtoNumber(newData.listItem[option].price) * (1+ data.VATRate) * value);
            }
            if (target === "price") {
                newData.listItem[option].total = matchRoad2(newData.listItem[option].nights * ((1 + data.VATRate) * changeStringVNDtoNumber(value)));
                newData.listItem[option].sub = matchRoad2(newData.listItem[option].nights * changeStringVNDtoNumber(value));
            }


        }
        setData(newData);
    }

    function renderItem() {
        let result = [];
        if(data.listItem.length > 0) {
            let indexColumn = 1;

            data.listItem.forEach((item, index) => {
                const listKeyOnItem = Object.keys(item);
                let result2 = [];

                function checkRowSpan() {
                    if(data.isUpdate) {
                        if(item.room_number !== "" && item.invoice !=="" && item.checkIn !=="" && item.checkOut !=="") {
                            return data.listHISpan[item.invoice+item.room_number]
                        } else if(item.room_number === "" && item.invoice === ""&& item.checkIn ==="" && item.checkOut ==="") return 1;
                        else if(item.room_number === "" && item.invoice !== "" && item.checkIn ==="" && item.checkOut ==="") return 1;
                        else if(item.room_number !== "" && item.invoice !== "" && item.checkIn ==="" && item.checkOut ==="") {
                            if(data.listHISpan[item.invoice+item.room_number] === 1) return 1
                            else return 0;
                        }
                        else return 0;
                    }
                }

                function checkIndexRowSpan() {
                    if(data.isUpdate) {
                        const keyOnListHISpan = Object.keys(data.listHISpan);
                        if(keyOnListHISpan.indexOf(item.invoice+item.room_number) === -1) {
                            indexColumn += 1; 
                        } else {
                          return  keyOnListHISpan.indexOf(item.invoice+item.room_number) + 1;
                        }
                    }

                    return indexColumn;
                }

                function checkTarget(key) { 
                    switch(key) {
                        case "name" : 
                            return <textarea value={item[key]}
                                style={{
                                        width: "100%",
                                        fontSize:'1.3rem',
                                        padding: "8px",
                                        height: "30px",
                                        border: "none",
                                   }}
                                className="no-border"
                                onChange={e => onChangeData(e.target.value, key, index)}
                            >
                            </textarea>
                        case "VAT_invoice" : case "nights" : return <input 
                            value={item[key]} 
                            type="number"
                            className="no-border"
                            style={{width:'100%' , padding: '8px 4px', textAlign: 'center'}}
                            onChange = {e => onChangeData(e.target.value, key, index)}
                        ></input>
                        case "checkIn" : return <div style={{fontSize: '1.3rem'}} className='makeRoom-checkInOut'>
                            <DatePicker
                                    className="no-border makeRoom-checkInOut_input"
                                    selected={item.checkIn === "" ? "" : new Date(item.checkIn)}
                                    dateFormat={data.dateFormat}
                                    onChange={(date) => onChangeData(date, "checkIn", index)} 
                                    selectsStart
                                    startDate=""
                                    disabled={item.room_number === ""}
                                    // startDate={dataRender.checkIn === "" ? "" : new Date(dataRender.checkIn)}
                                    endDate={item.checkOut === "" ? "" : new Date(item.checkOut)}
                                    //minDate={dataRender.checkIn === "" ? "" :  new Date(dataRender.checkIn)} 
                                    monthsShown={2}
                                    popperClassName="some-custom-class"
                                    popperPlacement="bottom-start"
                                    popperModifiers={[
                                        {
                                        name: "offset",
                                        options: {
                                            offset: [5, 10],
                                        },
                                        },
                                        {
                                        name: "preventOverflow",
                                        options: {
                                            rootBoundary: "viewport",
                                            tether: false,
                                            altAxis: true,
                                        },
                                        },
                                    ]}
                                />
                            </div>
                        case "checkOut": return <div style={{fontSize: '1.3rem'}} className='makeRoom-checkInOut'>
                            <DatePicker
                                    className="no-border makeRoom-checkInOut_input"
                                    selected={item.checkOut === "" ? "" :  new Date(item.checkOut)} 
                                    dateFormat={data.dateFormat}
                                    onChange={(date) => onChangeData(date, "checkOut", index)} 
                                    selectsEnd
                                    disabled={item.room_number === ""}
                                    startDate={item.checkIn === "" ? "" :  new Date(item.checkIn)} 
                                    endDate={item.checkOut === "" ? "" :  new Date(item.checkOut)} 
                                    minDate={item.checkIn === "" ? "" :  new Date(item.checkIn)} 
                                    monthsShown={2}
                                    popperClassName="some-custom-class"
                                    popperPlacement="bottom-start"
                                    popperModifiers={[
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [5, 10],
                                          },
                                        },
                                        {
                                          name: "preventOverflow",
                                          options: {
                                            rootBoundary: "viewport",
                                            tether: false,
                                            altAxis: true,
                                          },
                                        },
                                      ]}
                                />
                        </div>
                        case "price": return  <NumberFormat
                                    thousandSeparator={true}
                                    type="text"
                                    value={item[key]}
                                    className="no-border"
                                    style={{width:'100%' , padding: '8px 4px', textAlign: 'center'}}
                                    onChange = {e => onChangeData(e.target.value, key, index)}
                                />
                        case "VAT": return <div 
                                style={{width:'100%' , padding: '8px 4px', border: 'unset', textAlign: 'center'}}>
                                {item.nights !== "" && (changeStringVNDtoNumber(item.price)*data.VATRate * (item.nights ? item.nights : 1)).toLocaleString()}
                            </div>
                        case "sub": return <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                disable
                                                value={item[key]}
                                                style={{width:'100%' , padding: '8px 4px', border: 'unset', textAlign: 'center'}}
                                                onChange = {e => onChangeData(e.target.value, key, index)}
                                            />
                        case "total": return  <NumberFormat
                                                    thousandSeparator={true}
                                                    type="text"
                                                    value={item[key]}
                                                    disabled={item.nights !== ""}
                                                    className="no-border"
                                                    style={{width:'100%' , padding: '8px 4px', textAlign: 'center', color:"black"}}
                                                    onChange = {e => onChangeData(e.target.value, key, index)}
                                                />                      
                        default: return <input 
                                value={item[key]} 
                                style={{width:'100%' , padding: '8px 4px', textAlign: 'center'}}
                                className="no-border"
                                onChange = {e => onChangeData(e.target.value, key, index)}
                            ></input>
                    }
                }
                
                listKeyOnItem.forEach(key => {
                    result2.push(<td 
                            style={{border:'1px solid', fontSize:'1.3rem'}} 
                            rowSpan={((key ==="VAT_invoice" || key === "invoice")) ? checkRowSpan() :""}
                            className={(checkRowSpan() === 0 && (key ==="VAT_invoice" || key === "invoice"))? "op-hidden": 
                                            (data.hiddenRoomNumber && key === "room_number") ? "no-print" :""}
                        >
                        {checkTarget(key)}
                    </td>)
                })
//op-hidden
                result.push(<tr style={{fontSize: '1.2rem'}} >
                    <td style={{border: '1px solid'}}
                        className="no-print">
                        <input type="checkbox"
                                checked={data.listSelectDelete.includes(index)}
                                onChange={()=>checkSelectDelete(index)}></input>
                    </td>
                    <td 
                        rowSpan={checkRowSpan()}
                        style={{border: '1px solid'}}
                        className={(checkRowSpan() === 0)? "op-hidden": ""}
                        ><div style={{width:'100%' , padding: '8px 4px'}}>{checkIndexRowSpan()} </div>
                    </td>
                    {result2}
                </tr>)
            })
        }

       // if(data.isUpdate) setData({...data,isUpdate: false})
        return result;

    }

    function checkTotalVAT() {
        if(data.listItem.length > 0) {
            sumVAT = data.listItem.reduce(function (accumulator, currentValue) {
               // return accumulator + currentValue.VAT;

                return accumulator + changeStringVNDtoNumber(currentValue.total)
            }, 0);
        }
        return typeof sumVAT === "number" && sumVAT.toLocaleString();
    }

    function renderRowWithText(text1, text2, width) {
        //minWidth: width
        const setMinWith = (text2 === "Check in" || text2 === "Check out" 
            || text2 === "Guest's name/ Service" | text2 === "Total"
            || text2 === "Sub" || text2 === "Unit price"
            || text2 === "Hotel invoice") ? width : ""
        return (
            <th style={{
                border:'1px solid black', 
                fontSize:'1.3rem', 
                // backgroundColor: "#9966FF", 
                backgroundColor: "#9966ff35", 
                width: width, 
                minWidth: data.listItem.length  > 2 ? width : setMinWith
                // maxWidth: setMinWith
            }}
                className={text2 ==="Room number" ? (data.hiddenRoomNumber ? "no-print" :"") : ""}>
            <div>{text1}</div>
            <div>{text2}</div>
           </th>
        )
    }

    function closeModal() { dispatch(modalAction.modalReset()); }
    function openModal() { dispatch(modalAction.modalIsOk()); }

    return (<div className="pmrq">
        <h1 className="no-print">
            Make Paymment Request
            <div className="no-print btn btn-primary ml-3"
                onClick={()=> {window.open("https://www.youtube.com/embed/Hn4AH9jjlvY?si=jTIYr0qijdZJ_HHW")}}>Video tutorial</div>
        </h1>
        <div className="HI_header d-flex justify-content-between mt-5" style={{ width: '1100px' }}></div>
        <div className="d-flex">
                <div align="right">
                    <img 
                      style={{ width: '70px' }}
                      src="https://database.azumayareport.com/img/logo/logo192.png" 
                      className="img-responsive logo"
                      alt="logo" />
                        
                </div>
                {
                    data.branchInformation.name !== "Azumaya" && <div style={{ textAlign: "left", marginLeft:'12px' }}>
                        <div style={{ fontSize: '1.6rem', width: '100%', fontWeight: '500' }}>{ADDRESS_CHANGE[data.branchInformation.name].name}</div>
                        <div style={{ fontSize: '1.4rem', width: '100%', fontWeight: '500' }}>{ADDRESS_CHANGE[data.branchInformation.name].address}</div>
                        <div style={{ fontSize: '1.4rem' }}>{ADDRESS_CHANGE[data.branchInformation.name].phone}</div>
                   </div>
                }   
        </div>

        <h1 className="pmrq_title">
            ĐỀ NGHỊ THANH TOÁN
        </h1>
        <h1 className="pmrq_title">PAYMENT REQUEST</h1>

        <div className="pmrq_dear d-flex">
            <div style={{fontSize:'1.4rem', marginRight: '48px'}}>Kính gửi (To):</div>  
            <div className="d-fex" style={{width:'450px', textAlign:'left'}}>
                 <input value={data.companyName} 
                       style={{fontSize:'1.4rem', width:'120%'}}  
                       onChange={e => onChangeData(e.target.value, "companyName")}
                       className="no-border"
                         ></input>
                {/* <div style={{fontSize:'1.4rem', textAlign:'left'}}>Phòng kế toán</div> */}
                <input value={data.specialNote} 
                       onChange={e => onChangeData(e.target.value, "specialNote")}
                       style={{fontSize:'1.4rem', width:'120%'}}  
                       className="no-border"
                ></input>
            </div>
        </div>

        <br/>

        <div className="pmrq_function no-print">
           <button className="btn btn-primary mr-2"
                    onClick={() => addMoreColumn()}>+ Add Row</button> 
           <button className="btn btn-success mr-2"
                    onClick={() => addMoreHI()}>+ Add HI</button> 
            <button className="btn btn-danger"
                    onClick={() => {
                        const newData = {...data}
                        newData.listSelectDelete.forEach(deleteIndex => {
                            newData.listItem.splice(deleteIndex,1)
                        })
                        newData.listSelectDelete = [];
                        updateListHISpan(newData)
                    }}>- Delete Row</button>   
              
              <label className="ml-3">
                <strong style={{fontSize:"1.3rem"}}> Date Format: </strong>
                <select className="pmrq_function-select"
                        onChange={e => onChangeData(e.target.value, "dateFormat")} >
                        <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                        <option value="dd/MMM/yyyy">dd/MMM/yyyy</option>
                        <option value="yyyy/MM/dd">yyyy/MM/dd</option>
                        <option value="yyyy/MMM/dd">yyyy/MMM/dd</option>
                </select>
              </label>

              <label className="ml-3">
                <strong style={{fontSize:"1.3rem"}}> VAT: </strong>
                <select className="pmrq_function-select"
                        onChange={e => onChangeData(e.target.value, "VATRate")} >
                        <option value={RATE_VAT}>{`${RATE_VAT*100}%`}</option>
                        <option value={0.1}>10%</option>
                </select>
              </label>

              <label className="ml-3">
                       <strong style={{fontSize:"1.3rem"}}>Currency: </strong>
                       <select className="pmrq_function-select"
                            onChange={e => onChangeData(e.target.value, "bankAccount")} >
                            <option value={"VND"}>VND</option>
                            <option value={"USD"}>USD</option>
                        </select>
             </label>

             <label className="ml-3">
                       <strong style={{fontSize:"1.3rem"}}>Hidden Room Number: </strong>
                       <input type="checkbox" className="pmrq_function-checkbox"
                              onChange={() => onChangeData(!data.hiddenRoomNumber, "hiddenRoomNumber")}
                              checked={data.hiddenRoomNumber === true}></input>
             </label>

             {
                isHNAreaBranch && <label className="ml-3">
                        <strong style={{fontSize:"1.3rem"}}>Pay before 2 day check in </strong>
                        <input type="checkbox" className="pmrq_function-checkbox"
                            onChange={() => onChangeData(!data.payBefore2Day, "payBefore2Day")}
                            checked={data.payBefore2Day === true}></input>
                  </label>
             }


            <label className="ml-3">
                     <strong style={{fontSize:"1.3rem"}}>Hidden date: </strong>
                     <input type="checkbox" className="pmrq_function-checkbox"
                              onChange={() => onChangeData(!data.hiddenDate, "hiddenDate")}
                              checked={data.hiddenDate === true}></input>
            </label>
             
        </div>

        <table>
            <tr>
                <th style={{
                    border:'1px solid black', 
                    fontSize:'1.3rem', 
                    // backgroundColor: "#9966FF", 
                    backgroundColor: "#9966ff35", 
                    width:'40px'
                }}
                    className="no-print">
                    <div>{"check box"}</div>
                </th>
                {renderRowWithText("STT","No.","40px")}
                {renderRowWithText("Tên khách/ Dịch vụ","Guest's name/ Service","300px")}
                {renderRowWithText("HD khách sạn","Hotel invoive","90px")}
                {renderRowWithText("HD GTGT","Red invoive","100px")}
                {renderRowWithText("Ngày nhận phòng","Check in","90px")}
                {renderRowWithText("Ngày trả phòng","Check out","90px")}
                {renderRowWithText("Số phòng","Room number","100px")}
                {renderRowWithText("Số đêm","Night","100px")}
                 {/* No. of night*/}
                {renderRowWithText("Đơn giá","Unit price","90px")}
                {renderRowWithText("Tiền trước thuế","Sub","90px")}
                {renderRowWithText("Thuế GTGT",`VAT (${data.VATRate*100}%)`,"90px")}                    
                {renderRowWithText("Tiền sau thuế","Total","90px")}
            </tr>
            {data.listItem.length > 0 && renderItem()}

            <tr>
                <td className="pmrq_table_row no-print"/>
                <td className="pmrq_table_row">
                </td>
                <td className="pmrq_table_row">
                    <div><strong>{"Tổng tiền sau thuế"}</strong></div>
                    <div><strong>{"Total - Including VAT "}</strong></div>
                </td>
                <td className="pmrq_table_row"></td>
                <td className="pmrq_table_row"></td>
                <td className="pmrq_table_row"></td>
                <td className="pmrq_table_row"></td>
                <td className={data.hiddenRoomNumber ? "pmrq_table_row no-print" :"pmrq_table_row"}></td>
                <td className="pmrq_table_row"></td>
                <td className="pmrq_table_row"></td>
                <td className="pmrq_table_row"></td>
                <td className="pmrq_table_row"
                    colSpan={2}
                    style={{fontSize:'1.5rem', fontWeight:'800', textAlign:'right'}}>
                    {checkTotalVAT() +" " + data.bankAccount}
                </td>                     
                {/* <td className="pmrq_table_row"></td> */}
            </tr>
        </table>
       
            <div className="pmrq-footer mt-2">
                <div className="pmrq-footer_text">Quý khách vui lòng hợp tác với chúng tôi theo thông tin như sau (Please cooperate with us by below):</div>
                <div className="pmrq-footer_text font_weight_600 d-flex" style={{width:'100%'}}>
                    <div className="pmrq-hidden-when-have-not-print">
                        {
                            data.hiddenDate ? "Vui lòng thanh toán trước ngày (Make the payment before):" : `Vui lòng thanh toán trước ngày (Make the payment before): ${(data.payBefore2Day ? data.timeRequestPayment +" - ": "")} ${data.dateRequestPaymentDDYYMM}`
                        }
                    </div>
                    <div className="no-printer">{`Vui lòng thanh toán trước ngày (Make the payment before): `} </div>
                    
                    {data.payBefore2Day && <input className="no-printer" style={{width:'50px'}} value={data.timeRequestPayment} onChange={e => onChangeData(e.target.value,"timeRequestPayment",undefined)}></input>}
                    <div className="no-printer">
                        <DatePicker 
                            className="no-border makeRoom-checkInOut_input"
                            selected={data.dateRequestPayment} 
                            dateFormat={data.dateFormat}
                            onChange={(date) => onChangeData(date, "dateRequestPayment")} 
                            selectsEnd
                            monthsShown={2}
                            // disabled={item.room_number === ""}
                            popperClassName="some-custom-class"
                            popperPlacement="bottom-start"
                            popperModifiers={[
                                {
                                    name: "offset",
                                    options: {
                                    offset: [5, 10],
                                    },
                                },
                                {
                                    name: "preventOverflow",
                                    options: {
                                    rootBoundary: "viewport",
                                    tether: false,
                                    altAxis: true,
                                    },
                                },
                                ]}
                        />
                    </div>
                </div>
                <br/>
                <div className="d-flex" >
                    <div style={{width:"650px"}}>
                      <div className="pmrq-footer_text">Vui lòng thanh toán cho chúng tôi theo thông tin tài khoản:</div>
                      <div className="pmrq-footer_text">Please transfer by Banking following:</div>
                      <div className="pmrq-footer_text font_weight_600">Name Account: {BANK_ACCOUNT[data.branchInformation.name + " " + data.bankAccount].accountName}</div>
                      <div className="pmrq-footer_text font_weight_600">Number Account: {BANK_ACCOUNT[data.branchInformation.name + " "+ data.bankAccount].accountNumber}</div>
                      <div className="pmrq-footer_text font_weight_600">{BANK_ACCOUNT[data.branchInformation.name + " "+ data.bankAccount].bankName}</div>
                      {
                        data.bankAccount === "USD" && <>
                            <div className="pmrq-footer_text font_weight_600">Address: {BANK_ACCOUNT[data.branchInformation.name + " "+ data.bankAccount].bankAddress}</div>
                        </>
                      }
                      <div className="pmrq-footer_text font_weight_600">Swift Code:{BANK_ACCOUNT[data.branchInformation.name + " "+ data.bankAccount].swiftCode}</div>
                   </div>
               </div>
               <div className="pmrq-footer_sign" >
                    <div className="d-flex justify-content-center" style={{width:"300px", textAlign:'center'}}>
                      <div className="pmrq-footer_sign-text">{`${coverBranchIDToArea(Number(branchID))}, `}</div>
                      <DatePicker
                            className="no-border pmrq-footer_sign-input_date"
                            selected={new Date(data.signDate)} 
                            dateFormat={data.dateFormat}
                            onChange={(date) => onChangeData(date, "signDate")} 
                            selectsEnd
                            // disabled={item.room_number === ""}
                            popperClassName="some-custom-class"
                            popperPlacement="bottom-start" />
                    
                    </div>                    
                    <div style={{width:"300px", textAlign:'center'}}>Azumaya {data.branchInformation.name} {role === 0 ? "Receptionist" : "Accountant"}</div>
                    <input className="no-border pmrq-footer_sign-input"
                            value={data.signName}
                            onChange={e => onChangeData(e.target.value,"signName")}>

                    </input>
                    
               </div>
            </div>

       {
            auth.user.branchID && <FooterButton
                button1={"SAVE DATA"}
                iconBtn1="faSave"
                button2={"PRINT / SAVE PDF"}
                iconBtn2="faPrint"
                button3={"RESET DATA"}
                iconBtn3="faBan"
            //  width="1150px"
                onClickButton1={updatePaymentRequest}
                onClickButton2={() => updatePaymentRequest("print")}
                onClickButton3={resetPaymentRequest}
            />
        }

        <style>{`
          @media print {
            /* CSS print */
            @page {
                size: A4 landscape;
            }
        
            .no-border {
                border: none;
            }
        
            .makeRoom-checkInOut_input {
                background-color: white;
                text-align: center;
            }

            .pmrq-hidden-when-have-not-print {
                display: block;
            }

            textarea {
                border: none;
                resize: none;
                overflow: hidden;
            }
          }
        `}

        </style>

    </div>)
}

export default MakePMMRQContainer;
