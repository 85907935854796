import { reservationAction, getType } from '../../actions';

const initialState = {
    loading: true,
    month: "",
    branchID: 0,
    listRoom: {},
    listNumberRoom: [],
    listShowDate: [],
    listReservation: [],
    listCancel: [],
    listWaiting: [],
    listGuestFlow: [],
    listCompanyFlow: [],
    
    log: []
}

const reservationReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(reservationAction.fillRsvtData): {
            const { schedule, listReservation, listWaiting, listCancel, turnLoadingRoom} = action.payload;
            return {
                ...schedule,
                listReservation: listReservation,
                listWaiting: listWaiting,
                listCancel: listCancel,
                turnLoadingRoom,
                loading: false
            };
        }

        case getType(reservationAction.resetRsvtData): {
            return {
                loading: true,
                month: "",
                branchID: 0,
                listRoom: {},
                listNumberRoom: [],
                listShowDate: [],
                listReservation: [],
                turnLoadingRoom: {},
                listCancel: [],
                listWaiting: [],
                listGuestFlow: [],
                listCompanyFlow: [],
                log: []
            };
        }
        default: return state;
    }
}

export default reservationReducer;
