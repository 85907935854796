import React, { useEffect, useState, useRef } from 'react';
import {takeNoteBranch, changeNoteBranch} from '../../../api/branch';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch, faUser } from "@fortawesome/free-solid-svg-icons"; //faWallet, faFileInvoiceDollar, faQrcode, faNewspaper
// import './supportTools.css'
import { useSelector } from 'react-redux';

function SupportSaveNoteComponent(props) {
    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.token);
    const { role, branchID } = user;
    const [content, setContent] = useState("loading ....")
    const textareaRef1 = useRef(null);
    const textareaRef2 = useRef(null);
    const textareaRef3 = useRef(null);

    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState([
        "text1",
        "text2",
        "text3",
    ]);

    useEffect(() => {
        if(branchID) {
            const loadData = async () => {
                setLoading(true);
                setContent("Loading ...")
                const data = await takeNoteBranch(branchID, token);
                setNote(data.data.data);
                setLoading(false);
            }
            loadData();
        }
    }, [])

    function onChangeNote(value, target) {
        setLoading(false);
        const newNote = [...note];
        newNote[target] = value;
        setNote(newNote);
    }

    function onUpdateNote() {
        const updateData = async () => {
            setLoading(true);
            setContent("Loading ...")
            const data = await changeNoteBranch(branchID, note, token);
            setLoading(false);
        }
        updateData();
    }

    const handleCopy = (textSelect, text) => {
        if (textSelect.current) {
            textSelect.current.select(); // Chọn nội dung
          navigator.clipboard
            .writeText(textSelect.current.value) // Sao chép nội dung
            .then(() => {
                setLoading(true);
                setContent("Succes copying "+ text);
            })
            .catch((err) => {
              console.error("Không thể sao chép", err);
            });
        }
      };

    return (<div className="support_tools support_note scrollbar" id="style-4">
    <div className="d-flex p-2">
        <h2 className="support_tools__title">Support Quick Note</h2>
    </div>
    {loading && <div style={{fontSize:'1.2rem', color:"white"}}>{content}</div>}
    <p style={{ borderBottom: '1px solid #999' }}></p>
    <ul className="support_tools__list">
        <li className={"support_tools__list__item mb-3 d-flex"} style={{background:'white'}}>
            <textarea value={note[0]} className='support_note-text'
                ref={textareaRef1}
                onChange = {e => onChangeNote(e.target.value, 0)}></textarea>
            <i class="fa-solid fa-copy support_note-copy_icon" 
                onClick = {() => handleCopy(textareaRef1,"text1")}></i>    
        </li>
        
        <li className={"support_tools__list__item mb-3 d-flex"} style={{background:'white'}}>
            <textarea value={note[1]} className='support_note-text'
                ref={textareaRef2}
                onChange = {e => onChangeNote(e.target.value, 1)}></textarea>
            <i class="fa-solid fa-copy support_note-copy_icon"
                onClick = {() => handleCopy(textareaRef2,"text2")}></i>    
        </li>

        <li className={"support_tools__list__item mb-3 d-flex"} style={{background:'white'}}>
            <textarea value={note[2]} className='support_note-text'
                ref={textareaRef3}
                onChange = {e => onChangeNote(e.target.value, 2)}></textarea>
            <i class="fa-solid fa-copy support_note-copy_icon" 
                onClick = {() => handleCopy(textareaRef3,"text3")}></i>       
        </li>
        <button className='btn btn-primary p-2'
            onClick={() => onUpdateNote()} 
            >SAVE CHANGE NOTE</button>
        <br/>
        <br/>

    </ul>
</div >)
}

export default SupportSaveNoteComponent;
