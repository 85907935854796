import React from "react";
import { useSelector } from "react-redux";

function SupportHk1(props) {
    const dataGlist = useSelector(state => state.glist);
    const { listSupportHK1 } = dataGlist;

   
    //listSupport, value, room, targetTable, targetValue
    // const newData = {...dataGlist[listSupport]};
    // newData[targetTable][room][targetValue] = value;

    function renderListHK(dataRender, text, row) {
        return (
            <tr style={{height:'140px'}}>
                <td style={{fontSize:'1.5rem', width:'70px', border:'1px solid'}}>{text}</td>
                <td style={{fontSize:'1.8rem', width:'240px', border:'1px solid'}}>
                    <textarea className="no-border"
                              value={dataRender.in} 
                              disabled={!row}
                              style={{width:"100%", height:"125px"}} 
                              onChange={e => props.changeSupportHk("listSupportHK1",e.target.value, "in", row, undefined)}
                              ></textarea>
                </td>
                <td style={{fontSize:'1.8rem', width:'240px', border:'1px solid'}}>
                    <textarea value={dataRender.stay} 
                              style={{width:"100%", height:"125px"}} 
                              disabled={!row}
                              onChange={e => props.changeSupportHk("listSupportHK1",e.target.value, "stay", row, undefined)}
                              className="no-border"></textarea>
                </td>
                <td style={{fontSize:'1.8rem', width:'240px', border:'1px solid'}}>
                    <textarea value={dataRender.out} 
                              style={{width:"100%", height:"125px"}} 
                              disabled={!row}
                              onChange={e => props.changeSupportHk("listSupportHK1",e.target.value, "out", row, undefined)}
                              className="no-border"></textarea>
                </td>
                <td style={{fontSize:'1.8rem', width:'240px', border:'1px solid'}}>
                    <textarea value={dataRender.available} 
                              style={{width:"100%", height:"125px"}} 
                              disabled={!row}
                              onChange={e => props.changeSupportHk("listSupportHK1",e.target.value, "available", row, undefined)}
                              className="no-border"></textarea>
                </td>
            </tr>
        )    
    }

    return (
    <>
        <table className="support_HK">
            <thead>
                <tr>
                    <th style={{border:'1px solid'}}>Tầng</th>
                    <th style={{border:'1px solid'}}>Check in</th>
                    <th style={{border:'1px solid'}}>Khách ở</th>
                    <th style={{border:'1px solid'}}>Check out</th>
                    <th style={{border:'1px solid'}}>Trống</th>
                </tr>
            </thead>
            <tbody>
                {renderListHK(listSupportHK1.row1, "Tầng 1-4", "row1")}
                {renderListHK(listSupportHK1.row2, "Tầng 5-8", "row2")}
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>

        <table className="support_HK">
            <thead>
                <tr>
                    <th style={{border:'1px solid'}}>Tầng</th>
                    <th style={{border:'1px solid'}}>Check in</th>
                    <th style={{border:'1px solid'}}>Khách ở</th>
                    <th style={{border:'1px solid'}}>Check out</th>
                    <th style={{border:'1px solid'}}>Trống</th>
                </tr>
            </thead>
            <tbody>
                {renderListHK(listSupportHK1.row1, "Tầng 1-4", )}
                {renderListHK(listSupportHK1.row2, "Tầng 5-8", )}
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </>
    );
}

export default SupportHk1;

