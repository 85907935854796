export const LIST_BRANCH_NO_MASSAGE = [3, 4, 8, 12, 15, 99];
export const LIST_BRANCH_BREAK_FAST_WITH_MENU = [1]; //listBranchBreakFastWithMenu
export const LIST_BLOCK_GUEST_NAME = [
    "LOCK", "KEEP",
    "AZTVL1", "AZTVL2", "AZLTT",
    "AZDN", "AZHP",
    "AZKM1", "AZKM2", "AZKM3", "AZHBT1", "AZLL", "AZLL2", "AZDT",
    "THAI VAN LUNG 1", "THAI VAN LUNG 2", "THAI VAN LUNG1", "THAI VAN LUNG2", "LE THANH TON",
    "KIM MA", "LINH LANG", "HAI BA TRUNG",
    "DA NANG", "HAI PHONG", "DAO TAN"
]; 
export const LIST_GUEST_ID_BLOCK = [
    "6375c01ca808c1b418a0b148", // KM3
    "6375f9dcf339c59c9b77c402", // KM2
    "6375f9e5f339c59c9b77c405", // LL
    "6375f9eaf339c59c9b77c408", // HBt1
    "67bbe3ff110ce9817bdd187d", // DT
    "6375f9f0f339c59c9b77c40b", // TVL1
    "6375f9f4f339c59c9b77c40e", // TVL2
    "6375f9f8f339c59c9b77c411", // LTT
    "6375f9fdf339c59c9b77c414", // DN
    "6375fa01f339c59c9b77c417", // HP
    "6375fa05f339c59c9b77c41a", // CAM
    "637601aa8862f1ab0adb1796", // LOCK ROOM

    "652c90cfbfbc0ba470bffb23", // KM3 - 99
    "652c90f4bfbc0ba470bffb24", // KM2- 99
    "652c9107bfbc0ba470bffb26", // LL  -99
    "652c90fdbfbc0ba470bffb25", // HBT1 - 99
    "652c9140bfbc0ba470bffb2a", // TVL1 - 99
    "652c9146bfbc0ba470bffb2b", // TVL2 - 99
    "652c914fbfbc0ba470bffb2c", // LTT - 99
    "652c911dbfbc0ba470bffb27", // DN - 99
    "652c9129bfbc0ba470bffb28", // HP - 99
    "652c9138bfbc0ba470bffb29", // CAM - 99
    "652c92aabfbc0ba470bffb2d", // LOCK ROOM

    "653b39a624c4b94061ade759", // KM2 - 99
    "653b39a624c4b94061ade75a", // AZLL - 99
    "653b39a624c4b94061ade75a", // AZHBT1 - 99 
    "653b39a624c4b94061ade758", // AZKM3 - 99
    "653b39a624c4b94061ade760", // AZHP - 99
    "653b39a624c4b94061ade75f", // AZDN - 99
    "653b39a624c4b94061ade75c", // AZTVL1 - 99
    "653b39a624c4b94061ade75d", // AZTVL2 - 99
    "653b39a624c4b94061ade75e", // AZLTT - 99
    "653b39a624c4b94061ade761", // AZPP - 99
    "653b39a624c4b94061ade763", // LOCK ROOM -99
]

export const SELECT_KEEP_WITH_BRANCH_ID= {
    "1":"6375f9f0f339c59c9b77c40b", // TVL1
    "3":"6375f9eaf339c59c9b77c408", // HBt1
    "4":"6375f9f8f339c59c9b77c411", // LTT
    "7":"6375f9fdf339c59c9b77c414", // DN
    "9":"6375f9f4f339c59c9b77c40e", // TVL2
    "8":"6375f9dcf339c59c9b77c402", // KM2
    "11":"6375fa05f339c59c9b77c41a", // CAM
    "12":"6375f9e5f339c59c9b77c405", // LL
    "15":"6375c01ca808c1b418a0b148", // KM3
    "16":"67bbe3ff110ce9817bdd187d",
    "13":"6375fa01f339c59c9b77c417", // HP
    "lock":"637601aa8862f1ab0adb1796", // LOCK ROOM
}

export const LIST_BRANCH_WITH_AREA = {
	"Ha Noi": [
        {
			"branchID": 3,
			"name": "Hai Ba Trung 1",
			"area": "Ha Noi"
		},
		{
			"branchID": 8,
			"name": "Kim Ma 2",
			"area": "Ha Noi"
		},
		{
			"branchID": 15,
			"area": "Ha Noi",
			"name": "Kim Ma 3"
		},
		// {
		// 	"branchID": 99,
		// 	"name": "Branch Test",
		// 	"area": "Ha Noi"
		// },
		{
			"branchID": 12,
			"name": "Linh Lang",
			"area": "Ha Noi"
		},
		{
			"branchID": 16,
			"name": "Dao Tan",
			"area": "Ha Noi"
		}
	],
	"Hai Phong / Da Nang": [
		{
			"branchID": 13,
			"area": "Hai Phong / Da Nang",
			"name": "Hai Phong"
		},
		{
			"branchID": 7,
			"area": "Hai Phong / Da Nang",
			"name": "Da Nang"
		}
	],
	"Ho Chi Minh": [
		{
			"branchID": 1,
			"area": "Ho Chi Minh",
			"name": "Thai Van Lung 1"
		},
		{
			"branchID": 9,
			"area": "Ho Chi Minh",
			"name": "Thai Van Lung 2"
		},
		{
			"branchID": 4,
			"area": "Ho Chi Minh",
			"name": "Le Thanh Ton"
		},
		// {
		// 	"branchID": 2,
		// 	"area": "Ho Chi Minh",
		// 	"name": "Annex"
		// }
	],
	"Cam/Man": [
		{
			"branchID": 11,
			"name": "Phnom Penh",
			"area": "Cam/Man"
		}
	]
}